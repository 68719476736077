<template>
  <section class="payment_section">
    <div class="container">
      <div v-if="!accessToken" class="row mobile-login">
        <div class="col-md-12 text-center">
          <p class="login-btn" @click="showModal = true">Click here to login.</p>
          or
        </div>
        <div v-if="showModal">
          <transition name="modal">
            <div class="modal-mask">
              <div class="modal-wrapper">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header eu-modal-header">
                      <h5 class="modal-title eu-modal-title" style="color: #fff">
                        User Login
                      </h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" @click="showModal = false" style="font-weight: 100">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="checkout_form">
                            <ValidationObserver>
                              <form @submit.prevent="customerLogin">
                                <div class="form-group required">
                                  <ValidationProvider name="Email" rules="email|required" v-slot="{ errors }">
                                    <input class="form-control" v-model="user.email" name="email" placeholder="Email"
                                      type="email" />
                                    <span style="color: red">{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </div>
                                <div class="form-group required">
                                  <ValidationProvider name="Password" rules="required" v-slot="{ errors }">
                                    <input type="password" v-model="user.password" name="password"
                                      placeholder="Password" class="form-control" />
                                    <span style="color: red">{{ errors[0] }}</span>
                                  </ValidationProvider>
                                  <span style="color: red" v-if="resError">{{
                                    resError
                                  }}</span>
                                </div>
                                <div class="form-group">
                                  <button class="btn login-btn">Login</button>
                                </div>
                              </form>
                            </ValidationObserver>
                          </div>
                          <div class="checkout_form" style="display: flex">
                            <router-link class="btn sign-up" style="margin-right: 15px" :to="{ name: 'registration' }">
                              Sign up
                            </router-link>

                            <button class="btn forget-btn">Forgot Password</button>
                          </div>
                          <br />
                          <div class="checkout_form login-btn">
                            <button class="btn social-login fb" @click="loginFacebook">
                              Login with facebook
                            </button>
                            <button class="btn social-login glog" @click="googleLogin">
                              Sign in with Google
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" @click="showModal = false">
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>

      <ValidationObserver v-slot="{ invalid }">
        <form @submit="bill">
          <div class="col-md-4 payment_container">
            <div class="col-lg-12">
              <div class="row">
                <div class="panel panel-default">
                  <div class="panel-heading panel-heading-bg">Shipping Address</div>
                  <div class="panel-body">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Name</label>
                      </div>
                      <div class="col-md-8">
                        <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                          <input type="text" name="name" v-model="shipping.name" class="form-control" />
                          <span v-if="!shipping.name" style="color: red">{{
                            errors[0]
                          }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Phone </label>
                      </div>
                      <div class="col-md-8">
                        <ValidationProvider name="Phone" rules="required|numeric|length:11" v-slot="{ errors }">
                          <input type="text" name="phone" v-model="shipping.phone" class="form-control" required />
                          <span style="color: red">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Email address
                          <span style="color: red">(Optional)</span>
                        </label>
                      </div>
                      <div class="col-md-8">
                        <ValidationProvider rules="email" v-slot="{ errors }">
                          <input type="text" name="email" v-model="shipping.email" class="form-control" />
                          <span style="color: red">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Address </label>
                      </div>
                      <div class="col-md-8">
                        <ValidationProvider name="Address" rules="required" v-slot="{ errors }">
                          <input type="text" name="Address" v-model="shipping.address" class="form-control" />
                          <span style="color: red">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Division </label>
                      </div>
                      <div class="col-md-8">
                        <ValidationProvider name="division" rules="required" v-slot="{ errors }">
                          <b-form-select id="division" value-field="districts" text-field="name" v-model="divisions"
                            :options="locations" class="form-control" required />

                          <span v-if="errors != ''" style="color: red">{{
                            errors[0]
                          }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>District </label>
                      </div>
                      <div class="col-md-8">
                        <ValidationProvider name="District" rules="required" v-slot="{ errors }">
                          <b-form-select id="district" value-field="areas" text-field="name" v-model="districts"
                            :options="divisions" class="form-control" required />

                          <span v-if="errors != ''" style="color: red">{{
                            errors[0]
                          }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Area </label>
                      </div>
                      <div class="col-md-8">
                        <ValidationProvider name="Area">
                          <b-form-select id="area" value-field="id" text-field="name" v-model="shipping.area_id"
                            :options="areas" class="form-control" />
                          <!--<span v-if="errors != ''" style="color: red">{{
                            errors[0]
                          }}</span>-->
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <br />
                      <h4 class="title billing_i">
                        Billing Address
                        <!--{{ locations[0].id}}-->
                        <input type="checkbox" tabindex="112" value="0" v-model="selectedBilling" class="myCheckbox"
                          id="sameasshippingaddresscheckbox" name="sameasshippingaddresscheckbox"
                          onclick="showorhideCheekedOpt(selectedBilling);" />
                        <i> (Same as shiping address )</i>
                      </h4>
                    </div>
                    <div v-if="!selectedBilling">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Name</label>
                        </div>
                        <div class="col-md-8">
                          <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                            <input type="text" name="name" v-model="shipping.billing_name" class="form-control" />
                            <span v-if="!shipping.billing_name" style="color: red">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Phone </label>
                        </div>
                        <div class="col-md-8">
                          <ValidationProvider rules="numeric|length:11|required" v-slot="{ errors }">
                            <input type="text" name="phone" v-model="shipping.billing_phone" class="form-control" />
                            <span style="color: red">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Email address
                            <span style="color: red">(Optional)</span></label>
                        </div>
                        <div class="col-md-8">
                          <ValidationProvider rules="email" v-slot="{ errors }">
                            <input type="text" name="email" v-model="shipping.billing_email" class="form-control" />
                            <span style="color: red">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Address </label>
                        </div>
                        <div class="col-md-8">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <input type="text" name="Address" v-model="shipping.billing_address" class="form-control" />
                            <span style="color: red">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Division </label>
                        </div>
                        <div class="col-md-8">
                          <ValidationProvider name="division" rules="required" v-slot="{ errors }">
                            <b-form-group>
                              <b-form-select id="division" value-field="districts" text-field="name"
                                v-model="divisionsBilling" :options="locations" class="form-control" required />
                            </b-form-group>
                            <span v-if="errors != ''" style="color: red">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>District </label>
                        </div>
                        <div class="col-md-8">
                          <ValidationProvider name="District" rules="required" v-slot="{ errors }">
                            <b-form-group>
                              <b-form-select id="district" value-field="areas" text-field="name"
                                v-model="districtsBilling" :options="divisionsBilling" class="form-control" required />
                            </b-form-group>
                            <span v-if="errors != ''" style="color: red">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Area </label>
                        </div>
                        <div class="col-md-8">
                          <ValidationProvider name="Area">
                            <b-form-group>
                              <b-form-select id="area" value-field="id" text-field="name"
                                v-model="shipping.billing_area_id" :options="areasBilling" class="form-control" />
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="newsletter" />
                    <label class="form-check-label" for="newsletter">Sing Up for Newsletter</label>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>Additional notes <span style="color: red">(Optional)</span></label>
                    <textarea class="form-control" rows="3" v-model="shipping.comments"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <div class="form-check">
                      <ValidationProvider :rules="{ required: { allowFalse: false } }" v-slot="{ errors }" name="terms">
                        <input class="form-check-input" type="checkbox" id="terms" name="terms"
                          v-model="shipping.terms_and_condition" />
                        <label class="form-check-label" for="terms">I agree to the <span>terms & conditions</span> and
                          the privacy
                          policy.</label>
                        <br />
                        <span style="color: red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="payment_container col-md-8">
            <div v-if="!accessToken" class="row desktop-login">
              <div class="col-md-12">
                <div class="panel panel-default">
                  <div class="panel-heading panel-heading-bg">User Login</div>
                  <div class="panel-body">
                    <div class="row">
                      <div class="col-md-6 col-md-offset-3">
                        <div class="checkout_form">
                          <div class="form-group required">
                            <input class="form-control" v-model="user.email" name="email" placeholder="Email"
                              type="email" />
                          </div>
                          <div class="form-group required">
                            <input type="password" v-model="user.password" name="password" placeholder="Password"
                              class="form-control" />
                          </div>
                          <div class="form-group">
                            <button class="btn login-btn" type="button" @click="customerLogin">
                              Login
                            </button>
                          </div>
                        </div>
                        <div class="checkout_form" style="display: flex">
                          <router-link class="btn sign-up" style="margin-right: 15px" :to="{ name: 'registration' }">
                            Sign up
                          </router-link>

                          <button class="btn forget-btn">Forgot Password</button>
                        </div>
                        <br />
                        <div class="checkout_form login-btn">
                          <button class="btn social-login fb" @click="loginFacebook">
                            Login with facebook
                          </button>
                          <button class="btn social-login glog" @click="googleLogin">
                            Sign in with Google
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="panel panel-default">
                <div class="panel-heading panel-heading-bg">Order Review</div>
                <div class="panel-body">
                  <div class="cart-table">
                    <table class="table cart-table">
                      <thead style="background: #eaeaea">
                        <tr>
                          <td>Picture</td>
                          <td>Product Name</td>
                          <td class="text-center">Price</td>
                          <td class="text-center">QTY</td>
                          <td>Total Price</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in shoppingBag" :key="index">
                          <td>
                            <img class="payment-cart-img" v-if="item.cartImage" :src="'https://eurasiasupplies.com/euadmin/' + item.cartImage
                              " alt="" />
                          </td>
                          <td>
                            {{ item.name }}
                          </td>
                          <td class="text-center">
                            <!-- ৳{{ item.amount / item.qty }} -->
                            <!--   price e problem hole comment uthiye dite hobe   -->
                            <template>
                              <div v-if="
                                $store.getters.countGroupProduct(
                                  item.group_product_id
                                ) > 0
                              ">
                                <p class="unitPrice" v-if="
                                  item.product_price[0].tier_quantity_three > 0 &&
                                  $store.getters.countGroupProduct(
                                    item.group_product_id
                                  ) >= item.product_price[0].tier_quantity_three
                                ">
                                  ৳ {{ item.product_price[0].tier_price_three }}
                                </p>

                                <p class="unitPrice" v-else-if="
                                  $store.getters.countGroupProduct(
                                    item.group_product_id
                                  ) >= item.product_price[0].tier_quantity_two &&
                                  item.product_price[0].tier_quantity_two !== 0 &&
                                  $store.getters.countGroupProduct(
                                    item.group_product_id
                                  ) < item.product_price[0].tier_quantity_three
                                ">
                                  ৳ {{ item.product_price[0].tier_price_two }}
                                </p>
                                <p class="unitPrice" v-else-if="
                                  $store.getters.countGroupProduct(
                                    item.group_product_id
                                  ) >= item.product_price[0].tier_quantity_two &&
                                  item.product_price[0].tier_quantity_two !== 0 &&
                                  item.product_price[0].tier_price_three === 0
                                ">
                                  ৳ {{ item.product_price[0].tier_price_two }}
                                </p>
                                <!--T1-->
                                <!--                <h4 class="uPrice" v-else-if="item.product_price[0].tier_quantity_one>0 &&  $store.getters.countGroupProduct(item.group_product_id) >= item.product_price[0].tier_quantity_one && $store.getters.countGroupProduct(item.group_product_id) < item.product_price[0].tier_quantity_two ">৳ {{item.product_price[0].tier_price_one}} </h4>-->
                                <p class="unitPrice" v-else-if="
                                  $store.getters.countGroupProduct(
                                    item.group_product_id
                                  ) >= item.product_price[0].tier_quantity_one &&
                                  item.product_price[0].tier_quantity_one !== 0 &&
                                  $store.getters.countGroupProduct(
                                    item.group_product_id
                                  ) < item.product_price[0].tier_quantity_two
                                ">
                                  ৳ {{ item.product_price[0].tier_price_one }}
                                </p>
                                <p class="unitPrice" v-else-if="
                                  $store.getters.countGroupProduct(
                                    item.group_product_id
                                  ) >= item.product_price[0].tier_quantity_one &&
                                  item.product_price[0].tier_quantity_one !== 0 &&
                                  item.product_price[0].tier_price_two === 0
                                ">
                                  ৳ {{ item.product_price[0].tier_price_one }}
                                </p>
                                <p class="unitPrice" v-else-if="
                                  item.product_price[0].offer_price > 0 &&
                                  $store.getters.prodQty(item.id)
                                ">
                                  ৳{{ item.product_price[0].offer_price }}
                                </p>
                                <p class="unitPrice" v-else>
                                  ৳ {{ item.product_price[0].price }}
                                </p>
                              </div>
                              <div v-else>
                                <p class="unitPrice" v-if="
                                  item.product_price[0].tier_quantity_three > 0 &&
                                  $store.getters.prodQty(item.id) >=
                                  item.product_price[0].tier_quantity_three
                                ">
                                  ৳ {{ item.product_price[0].tier_price_three }}
                                </p>

                                <p class="unitPrice" v-else-if="
                                  $store.getters.prodQty(item.id) >=
                                  item.product_price[0].tier_quantity_two &&
                                  item.product_price[0].tier_quantity_two !== 0 &&
                                  $store.getters.prodQty(item.id) <
                                  item.product_price[0].tier_quantity_three
                                ">
                                  ৳ {{ item.product_price[0].tier_price_two }}
                                </p>
                                <p class="unitPrice" v-else-if="
                                  $store.getters.prodQty(item.id) >=
                                  item.product_price[0].tier_quantity_two &&
                                  item.product_price[0].tier_quantity_two !== 0 &&
                                  item.product_price[0].tier_price_three === 0
                                ">
                                  ৳ {{ item.product_price[0].tier_price_two }}
                                </p>
                                <p class="uPrice" v-else-if="
                                  item.product_price[0].tier_quantity_one > 0 &&
                                  $store.getters.prodQty(item.id) >=
                                  item.product_price[0].tier_quantity_one &&
                                  $store.getters.prodQty(item.id) <
                                  item.product_price[0].tier_quantity_two
                                ">
                                  ৳ {{ item.product_price[0].tier_price_one }}
                                </p>
                                <p class="unitPrice" v-else-if="
                                  $store.getters.prodQty(item.id) >=
                                  item.product_price[0].tier_quantity_one &&
                                  item.product_price[0].tier_quantity_one !== 0 &&
                                  $store.getters.prodQty(item.id) <
                                  item.product_price[0].tier_quantity_two
                                ">
                                  ৳ {{ item.product_price[0].tier_price_one }}
                                </p>
                                <p class="unitPrice" v-else-if="
                                  $store.getters.prodQty(item.id) >=
                                  item.product_price[0].tier_quantity_one &&
                                  item.product_price[0].tier_quantity_one !== 0 &&
                                  item.product_price[0].tier_price_two === 0
                                ">
                                  ৳ {{ item.product_price[0].tier_price_one }}
                                </p>
                                <p class="unitPrice" v-else-if="
                                  item.product_price[0].offer_price > 0 &&
                                  $store.getters.prodQty(item.id)
                                ">
                                  ৳{{ item.product_price[0].offer_price }}
                                </p>
                                <p class="unitPrice" v-else>
                                  ৳ {{ item.product_price[0].price }}
                                </p>
                              </div>
                            </template>
                          </td>
                          <td class="text-center">
                            <!--<p>{{ $store.getters.prodQty(item.id) }}</p>-->
                            <div class="price_qty">
                              <div class="group product-pricing qty-in-dec">
                                <button type="button" class="minus" style="padding: 0px" @click="deductQty(item)">
                                  <i class="fa fa-minus"></i>
                                </button>
                                <div class="product_value">
                                  <span class="quantity_value">{{
                                    $store.getters.prodQty(item.id)
                                  }}</span>
                                </div>
                                <button :disabled="item.product_price[0].quantity ===
                                  $store.getters.prodQty(item.id)
                                  " type="button" style="padding: 4px 0px" class="plus" @click="addQty(item)">
                                  <i class="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </td>
                          <td>
                            <template>
                              <div v-if="
                                $store.getters.countGroupProduct(
                                  item.group_product_id
                                ) > 0
                              ">
                                <p v-if="
                                  item.product_price[0].tier_quantity_three > 0 &&
                                  $store.getters.countGroupProduct(
                                    item.group_product_id
                                  ) >= item.product_price[0].tier_quantity_three
                                ">
                                  ৳{{
                                    item.product_price[0].tier_price_three *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>
                                <p v-else-if="
                                  $store.getters.countGroupProduct(
                                    item.group_product_id
                                  ) >= item.product_price[0].tier_quantity_two &&
                                  item.product_price[0].tier_quantity_two !== 0 &&
                                  $store.getters.prodQty(item.id) <
                                  item.product_price[0].tier_quantity_three
                                ">
                                  ৳{{
                                    item.product_price[0].tier_price_two *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>
                                <p v-else-if="
                                  $store.getters.countGroupProduct(
                                    item.group_product_id
                                  ) >= item.product_price[0].tier_quantity_two &&
                                  item.product_price[0].tier_quantity_two !== 0 &&
                                  item.product_price[0].tier_price_three === 0
                                ">
                                  ৳{{
                                    item.product_price[0].tier_price_two *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>
                                <p v-else-if="
                                  $store.getters.countGroupProduct(
                                    item.group_product_id
                                  ) >= item.product_price[0].tier_quantity_one &&
                                  item.product_price[0].tier_quantity_one !== 0 &&
                                  $store.getters.countGroupProduct(
                                    item.group_product_id
                                  ) < item.product_price[0].tier_quantity_two
                                ">
                                  ৳{{
                                    item.product_price[0].tier_price_one *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>
                                <p v-else-if="
                                  $store.getters.countGroupProduct(
                                    item.group_product_id
                                  ) >= item.product_price[0].tier_quantity_one &&
                                  item.product_price[0].tier_quantity_one !== 0 &&
                                  item.product_price[0].tier_price_two === 0
                                ">
                                  ৳{{
                                    item.product_price[0].tier_price_one *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>

                                <p v-else-if="
                                  item.product_price[0].offer_price > 0 &&
                                  $store.getters.prodQty(item.id)
                                ">
                                  ৳{{
                                    item.product_price[0].offer_price *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>
                                <p v-else>
                                  ৳{{
                                    item.product_price[0].price *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>
                              </div>
                              <div v-else>
                                <p v-if="
                                  item.product_price[0].tier_quantity_three > 0 &&
                                  $store.getters.prodQty(item.id) >=
                                  item.product_price[0].tier_quantity_three
                                ">
                                  ৳{{
                                    item.product_price[0].tier_price_three *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>
                                <p v-else-if="
                                  $store.getters.prodQty(item.id) >=
                                  item.product_price[0].tier_quantity_two &&
                                  item.product_price[0].tier_quantity_two !== 0 &&
                                  $store.getters.prodQty(item.id) <
                                  item.product_price[0].tier_quantity_three
                                ">
                                  ৳{{
                                    item.product_price[0].tier_price_two *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>
                                <p v-else-if="
                                  $store.getters.prodQty(item.id) >=
                                  item.product_price[0].tier_quantity_two &&
                                  item.product_price[0].tier_quantity_two !== 0 &&
                                  item.product_price[0].tier_price_three === 0
                                ">
                                  ৳{{
                                    item.product_price[0].tier_price_two *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>
                                <p v-else-if="
                                  $store.getters.prodQty(item.id) >=
                                  item.product_price[0].tier_quantity_one &&
                                  item.product_price[0].tier_quantity_one !== 0 &&
                                  $store.getters.prodQty(item.id) <
                                  item.product_price[0].tier_quantity_two
                                ">
                                  ৳{{
                                    item.product_price[0].tier_price_one *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>
                                <p v-else-if="
                                  $store.getters.prodQty(item.id) >=
                                  item.product_price[0].tier_quantity_one &&
                                  item.product_price[0].tier_quantity_one !== 0 &&
                                  item.product_price[0].tier_price_two === 0
                                ">
                                  ৳{{
                                    item.product_price[0].tier_price_one *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>

                                <p v-else-if="
                                  item.product_price[0].offer_price > 0 &&
                                  $store.getters.prodQty(item.id)
                                ">
                                  ৳{{
                                    item.product_price[0].offer_price *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>
                                <p v-else>
                                  ৳{{
                                    item.product_price[0].price *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>

                                <!-- <p v-if="
                                  item.product_price[0].tier_quantity_three > 0 &&
                                  $store.getters.prodQty(item.id) >=
                                  item.product_price[0].tier_quantity_three
                                ">
                                  ৳
                                  {{
                                    item.product_price[0].tier_price_three *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>
                                <p v-else-if="
                                  item.product_price[0].tier_quantity_two > 0 &&
                                  $store.getters.prodQty(item.id) >=
                                  item.product_price[0].tier_quantity_two &&
                                  $store.getters.prodQty(item.id) <
                                  item.product_price[0].tier_quantity_three
                                ">
                                  ৳
                                  {{
                                    item.product_price[0].tier_price_two *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>
                                <p v-else-if="
                                  item.product_price[0].tier_quantity_one > 0 &&
                                  $store.getters.prodQty(item.id) >=
                                  item.product_price[0].tier_quantity_one &&
                                  $store.getters.prodQty(item.id) <
                                  item.product_price[0].tier_quantity_two
                                ">
                                  ৳
                                  {{
                                    item.product_price[0].tier_price_one *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>
                                <p v-else-if="
                                  item.product_price[0].offer_price > 0 &&
                                  $store.getters.prodQty(item.id)
                                ">
                                  ৳
                                  {{
                                    item.product_price[0].offer_price *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p>
                                <p v-else>
                                  ৳
                                  {{
                                    item.product_price[0].price *
                                    $store.getters.prodQty(item.id)
                                  }}
                                </p> -->
                              </div>
                            </template>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3"></td>

                          <td colspan="2">
                            <div class="form-group required">
                              <ValidationProvider name="Voucher code" v-slot="{ errors }">
                                <div v-if="!isApplyCoupon" class="input-group input-group-sm">
                                  <input type="text" placeholder="Voucher code" name="cupon" v-model="coupon_code"
                                    class="form-control voucher-code" />
                                  <div class="input-group-btn">
                                    <!--                                  <button
                                                                        class="btn btn-danger"
                                                                        @click="isInActiveCupon()"
                                                                        type="button"
                                                                    >
                                                                      X
                                                                    </button>-->
                                    <button class="btn btn-success" @click="applyCoupon(coupon_code)" type="button">
                                      Apply
                                    </button>
                                  </div>
                                </div>
                                <p v-if="coupon_msg !== ''" style="color: red; text-align: center">
                                  <b>{{ coupon_msg }}</b>
                                </p>
                                <span style="color: red">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3"></td>
                          <td class="text-right"><strong>Sub Total :</strong></td>
                          <td>৳ {{ total_amt }}</td>
                        </tr>
                        <tr>
                          <td colspan="3"></td>
                          <td class="text-right">Shipping Price :</td>
                          <td>৳ {{ shippingCharge.shipping_charge }}</td>
                        </tr>

                        <tr>
                          <td colspan="3"></td>
                          <td class="text-right"><strong>Total :</strong></td>
                          <td>৳ {{ total_amt + shippingCharge.shipping_charge }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="panel panel-default">
                  <div class="panel-heading panel-heading-bg">
                    <strong>বিঃদ্রঃ</strong>
                  </div>
                  <div class="panel-body">
                    <p style="color: red; padding-top: 15px">
                      <!-- <strong
                        >বিঃদ্রঃ-ঢাকার বাইরে ডেলিভারি চার্জ ১২০/- অগ্রিম প্রদান করতে হবে
                        ।</strong
                      > -->
                      <strong>{{ siteSetting("shipping-cart-note-2").title }}
                        ।</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="panel panel-default">
                  <div class="panel-heading panel-heading-bg">Payment options</div>
                  <div class="panel-body">
                    <div class="col-xl-6 col-lg-5">
                      <div class="payment_total">
                        <p>
                          <input v-model="deleveryMethod" id="cod" value="true" :checked="deleveryMethod === true"
                            type="radio" />
                          <label for="cod">&nbsp; Cash On Delivery</label>
                        </p>
                        <figure class="payment_option">
                          <img :src="'https://eurasiasupplies.com/euadmin/storage/images/setting/' +
                            paymentMethod.image
                            " alt="" :key="index" v-for="(
                              paymentMethod, index
                            ) in paymentMethods.site_details.filter(
                                (e) => e.title === 'Cash On Delivery'
                              )" />
                        </figure>
                        <hr />
                        <p>
                          <input v-model="deleveryMethod" id="cod" value="2" :checked="deleveryMethod === true"
                            type="radio" />
                          <label for="cod">&nbsp; Payment with bKash</label>
                        </p>
                        <figure class="payment_option">
                          <img :src="'https://eurasiasupplies.com/euadmin/storage/images/setting/' +
                            paymentMethod.image
                            " alt="" :key="index" v-for="(
                              paymentMethod, index
                            ) in paymentMethods.site_details.filter(
                                (e) => e.title === 'Bkash'
                              )" />
                        </figure>
                        <hr />
                        <p>
                          <input v-model="deleveryMethod" id="op" value="3" :checked="deleveryMethod === false"
                            type="radio" />
                          <label for="op">&nbsp; Payment Online</label>
                        </p>
                        <figure class="payment_option">
                          <img :src="'https://eurasiasupplies.com/euadmin/storage/images/setting/' +
                            paymentMethod.image
                            " alt="" :key="index" v-for="(
                              paymentMethod, index
                            ) in paymentMethods.site_details.filter(
                                (e) => e.title !== 'Cash On Delivery'
                              )" />
                        </figure>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6">
                <div class="form-group text-center">
                  <button v-if="!checkOutLoading" :disabled="invalid" class="btn btn-success" type="submit">
                    CHECKOUT
                  </button>
                  <button v-if="checkOutLoading" disabled class="btn btn-success" type="button">
                    <i class="fa fa-spinner fa-spin" style="font-size: 15px; color: red"></i>
                    <img src="" alt="" /> Please Wait
                  </button>
                  <!--<button :disabled="invalid" class="confirm_btn" type="submit">
                    CHECKOUT
                </button>-->
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>

    <b-modal id="bv-modal-example" hide-footer>
      <div class="d-block text-center">
        <h3>Your order has been place successfully</h3>
      </div>
      <b-button class="mt-3">Ok</b-button>
    </b-modal>
    <!--<button>sf</button>-->
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import axios from "../../axios";

export default {
  name: "billing",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      deleveryMethod: true,
      selectedBilling: true,
      divisions: [],
      districts: [],
      areas: [],

      divisionsBilling: [],
      districtsBilling: [],
      areasBilling: [],
      shipping: {
        name: "",
        customer_id: "",
        shipping_charge: 0,
        phone: "",
        email: "",
        address: "",
        division_id: "",
        district_id: "",
        area_id: "",
        billing_name: "",
        billing_phone: "",
        billing_email: "",
        billing_address: "",
        billing_division_id: "",
        billing_district_id: "",
        billing_area_id: "",
        comments: "",
        terms_and_condition: true,
        payment_getway: "Cash on delivary",
        products: [],
        total_amt_digi_m: "",
        shipping_crg_digi_m: "",
      },
      showError: false,
      cupon: "",
      activeCupon: false,
      coupon_code: "",
      total_amt: 0,
      isApplyCoupon: false,
      coupon_msg: "",
      checkOutLoading: false,
      ecommerce: {
        items: {
          item_name: "",
          item_id: "",
          price: "",
          item_brand: "",
          item_category: "",
          item_category2: "",
          item_category3: "",
          item_category4: "",
          item_variant: "",
          item_list_name: "",
          item_list_id: "",
          index: 0,
          quantity: "",
        },
      },
      user: {
        email: "",
        password: "",
      },
      resError: "",
      showModal: false,
    };
  },
  computed: {
    ...mapGetters([
      "locations",
      "paymentMethods",
      "shoppingBag",
      "total",
      "customer",
      "shippingCharge",
      "me",
      "accessToken",
      'siteSetting',
    ]),
  },
  watch: {
    divisions(val) {
      if (val.length) {
        this.shipping.division_id = val[0].division_id;
      }
    },
    divisionsBilling(val) {
      if (val.length) {
        this.shipping.billing_division_id = val[0].division_id;
      }
    },
    districts(val) {
      if (val.length) {
        this.shipping.district_id = val[0].district_id;
        this.areas = val;
        // console.log("watch log", this.shipping.district_id, this.total);
        this.$store.dispatch("getShippingCharge", {
          district_id: this.shipping.district_id,
          total_amount: this.total,
        });
      }
    },
    districtsBilling(val) {
      if (val.length) {
        this.shipping.billing_district_id = val[0].district_id;
        this.areasBilling = val;
      }
    },
    coupon_code(val) {
      if (!val) {
        this.coupon_msg = "";
      }
    },
    total(val) {
      this.total_amt = val;
      if (this.shipping.district_id) {
        this.$store.dispatch("getShippingCharge", {
          district_id: this.shipping.district_id,
          total_amount: this.total_amt,
        });
      } else {
        this.$store.dispatch("getShippingCharge", {
          district_id: 1,
          total_amount: this.total_amt,
        });
      }
    },
    "user.email"(oldvalue, newVal) {
      if (newVal) {
        this.resError = "";
      }
    },
    "user.password"(oldvalue, newVal) {
      if (newVal) {
        this.resError = "";
      }
    },
  },

  mounted() {
    this.$store.dispatch("getMe");
    this.$store.dispatch("getcustomerSummery");
    this.$store.dispatch("setLocaitons");
    this.shoppingBagmap();
    // console.log("shoppingBag", this.shoppingBag);
    //this.shippingCharge.shipping_charge = 0
    if (this.me) {
      this.shipping.name = this.me.customer_name;
      this.shipping.email = this.me.customer_email;
      this.shipping.phone = this.me.customer_phone;
    }
    this.total_amt = this.total;
    this.$store.dispatch("getShippingCharge", {
      district_id: 1,
      total_amount: this.total_amt,
    });
    // console.log("INIT shippingCharge", this.shippingCharge);
    let allData = [];
    let mData = {
      item_name: "",
      item_id: "",
      price: "",
      item_brand: "",
      item_category: "",
      item_category2: "",
      item_category3: "",
      item_category4: "",
      item_variant: "",
      item_list_name: "",
      item_list_id: "",
      index: 0,
      quantity: "",
    };
    this.shoppingBag.forEach((item) => {
      mData.item_name = item.name;
      mData.item_id = item.id;
      mData.price = item.unitPrice;
      mData.item_brand = item.brand_id;
      mData.quantity = item.qty;
      allData.push(mData);
    });
    window.fbq("track", "InitiateCheckout", {
      value: this.total_amt,
      currency: "BDT",
      contents: allData,
      content_type: "product",
    });
  },

  methods: {
    bill(e) {
      this.checkOutLoading = true;
      e.preventDefault();
      // console.log("====>>>  ", this.shipping, this.deleveryMethod);
      this.shipping.billing_division_id = this.divisions.id;
      this.shipping.billing_district_id = this.districts.id;
      this.shipping.billing_address = this.shipping.address;
      this.shipping.billing_area_id = this.shipping.area_id || "";
      this.shipping.billing_name = this.shipping.name;
      this.shipping.billing_email = this.shipping.email;
      this.shipping.billing_phone = this.shipping.phone;
      this.shipping.shipping_charge = this.shippingCharge.shipping_charge;
      this.shipping.coupon_code = this.coupon_code;
      this.shipping.payment_getway =
        this.deleveryMethod === true
          ? "Cash on delivery"
          : this.deleveryMethod === "2"
            ? "Bkash"
            : this.deleveryMethod === "3"
              ? "Online Payment"
              : "Cash on delivery";
      if (this.customer) {
        this.shipping.customer_id = this.customer.customer.id;
      }
      this.shipping.total_amt_digi_m = this.total_amt;
      // console.log("Log-billing-->", this.shipping);
      let dataForDigiMarketers = {
        shippingData: this.shipping,
        cartData: this.shoppingBag,
      };
      this.$store.dispatch("validPurchaseItemsForDigitalMarketers", dataForDigiMarketers);
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          items: this.shoppingBag.forEach((item) => {
            this.item_name = item.name;
            this.item_id = item.id;
            this.price = item.unitPrice;
            (this.item_brand = item.brand_id),
              (this.item_category = ""),
              (this.item_category2 = ""),
              (this.item_category3 = ""),
              (this.item_category4 = ""),
              (this.item_variant = ""),
              (this.item_list_name = ""), // If associated with a list selection.
              (this.item_list_id = ""), // If associated with a list selection.
              (this.index = 0), // If associated with a list selection.
              (this.quantity = item.qty ?? 0);
          }),
        },
      });

      this.$store.dispatch("billing", this.shipping).then((res) => {
        // console.log("Log-billing-->", res);
        // if (res === undefined) {
        //   this.$store.dispatch('nullCart')
        //   this.$swal.fire ({
        //     icon: 'success',
        //     title: 'Yor order has been place successfully.',
        //     confirmButtonColor: '#f60000',
        //   });
        // } else {
        //   window.alert('Something Wrong !')
        // }
      });
    },
    shoppingBagmap() {
      if (this.shoppingBag.length) {
        const mapBag = this.shoppingBag.map((item) => {
          return {
            id: item.id,
            quantity: item.qty,
          };
        });
        this.shipping.products = mapBag;
      }
      // console.log("clock = ", this.shipping.products);
    },
    removeFromCart(item) {
      this.$store.dispatch("removeFromCart", item);
    },
    showorhideCheekedOpt() { },
    isActiveCupon() {
      this.activeCupon = true;
    },
    isInActiveCupon() {
      this.cupon = "";
      this.activeCupon = false;
    },
    addQty(product) {
      // console.log("Product add one ==>", product);
      // // console.log('update', product.product_price[0].tier_quantity_three)
      this.$store.dispatch("addQty", product);
      this.shoppingBagmap();
    },
    deductQty(product) {
      if (this.$store.getters.prodQty(product.id) > 0) {
        this.$store.dispatch("deductQty", product);
      }
      if (this.$store.getters.prodQty(product.id) === 0) {
        this.$store.dispatch("removeFromCart", product);
      }
      this.shoppingBagmap();
    },
    applyCoupon(coupon_code) {
      axios
        .post(`/coupons?coupon_code=${coupon_code}`)
        .then((response) => {
          // console.log("coupon = ", response);
          if (response.data.data.customer_id === null) {
            if (response.data.data.coupon_type === "flate") {
              this.total_amt = this.total_amt - parseInt(response.data.data.value);
              this.isApplyCoupon = true;
              this.coupon_msg =
                "Coupon Applied for flat discount " + response.data.data.value + "৳";
            }
            if (response.data.data.coupon_type === "percentage") {
              let pers = Math.ceil(
                (parseInt(response.data.data.value) / 100) * this.total_amt
              );
              this.total_amt = this.total_amt - pers;
              this.isApplyCoupon = true;
              this.coupon_msg =
                "Coupon Applied for discount " +
                response.data.data.value +
                "% amount of " +
                pers +
                "৳";
            }
            if (response.data.data.coupon_type === "freeshipping") {
              this.shippingCharge.shipping_charge = 0;
              this.isApplyCoupon = true;
              this.coupon_msg = "Coupon Applied for Free Shipping.";
            }
          }
          if (response.data.data.customer_id !== null) {
            if (response.data.data.customer_id === this.customer.id) {
              if (response.data.data.coupon_type === "flate") {
                this.total_amt = this.total_amt - parseInt(response.data.data.value);
                this.isApplyCoupon = true;
                this.coupon_msg =
                  "Coupon Applied for flat discount " + response.data.data.value + "৳";
              }
              if (response.data.data.coupon_type === "percentage") {
                let pers = Math.ceil(
                  (parseInt(response.data.data.value) / 100) * this.total_amt
                );
                this.total_amt = this.total_amt - pers;
                this.isApplyCoupon = true;
                this.coupon_msg =
                  "Coupon Applied for discount " +
                  response.data.data.value +
                  "% amount of " +
                  pers +
                  "৳";
              }
              if (response.data.data.coupon_type === "freeshipping") {
                this.shippingCharge.shipping_charge = 0;
                this.isApplyCoupon = true;
                this.coupon_msg = "Coupon Applied for Free Shipping.";
              }
            } else {
              this.coupon_msg = "You are not eligible for this coupon.";
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          this.coupon_msg = "Coupon not found.";
          this.isApplyCoupon = false;
        });
    },
    customerLogin(e) {
      // console.log("login", this.user);
      e.preventDefault();
      this.$store.dispatch("login", this.user).then((res) => {
        if (res === undefined) {
          this.$swal({
            toast: true,
            position: "top-end",
            title: "Successfully Logedin.",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
          //this.$router.push('/customer-dashboard').catch(() => {});
          this.user = {
            email: "",
            password: "",
          };
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            title: res.message,
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
    },
    loginFacebook() {
      window.location.href =
        "https://eurasiasupplies.com/eurasia_backedapi/login/facebook";
    },
    googleLogin() {
      window.location.href = "https://eurasiasupplies.com/eurasia_backedapi/auth/google";
    },
  },
};
</script>

<style scoped>
p.login-btn {
  background: red;
  color: #fff;
  padding: 9px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.payment_container {
  text-align: left;
}

.panel-default>.panel-heading-bg {
  color: #fff;
  background-color: #f11313;
}

.cupon_btn {
  cursor: pointer;
}

h5.unitPrice {
  font-size: 0.9rem;
  color: #7a7a8f;
}

img.payment-cart-img {
  height: auto;
  width: 60px;
  border-radius: 5px;
}

.cart-table tr:nth-child(odd) {
  background: #f9f9f9;
}

.cart-table tr td {
  border-top: none;
}

table {
  table-layout: fixed;
}

.minus {
  padding: 10px;
  height: 19px;
  line-height: 1px;
  border-radius: 3px;
  display: inline-block;
}

.plus {
  padding: 10px 9px;
  height: 19px;
  line-height: 2px;
  border-radius: 3px;
  display: inline-block;
}

figure.payment_option {
  display: flex;
}

figure.payment_option img {
  height: 50px;
  margin-right: 15px;
}

.panel-default {
  border-color: #f11313;
}

.qty-in-dec {
  display: inline-flex;
}

.product_value {
  margin: 0px 10px;
  font-size: 16px;
  font-weight: bold;
}

i.fa.fa-minus,
i.fa.fa-plus {
  color: #010101;
  padding: 0px 5px;
  font-size: 10px;
}

/*=====*/
.login-btn .social-login {
  width: 100%;
  display: block;
  color: #fff;
  padding: 8px 0px;
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 17px;
  text-transform: uppercase;
}

.guest-btn {
  background: #4caf50;
}

.guest-btn:hover {
  background: #09a005;
}

.fb {
  background: #1877f2;
}

.glog {
  background: red;
}

.form-group {
  text-align: left;
}

button.btn.login-btn {
  width: 100%;
  background: #4caf50;
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  border-radius: 3px;
}

button.btn.login-btn:hover {
  background: #09a005;
}

.container-fluid.full-login-wrap {
  background: gray;
  margin: 15px 0px;
}

a.btn.sign-up {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
  border-radius: 3px;
  color: #fff;
  background: #0a0a0a;
}

.btn.forget-btn {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
  border-radius: 3px;
  color: #fff;
  background: #0a0a0a;
}

.col-md-6.flex-part {
  display: flex;
  justify-content: space-between;
}

.checkout_form {
  width: 95%;
}

.or-border {
  border-right: 5px solid #ccc;
  position: relative;
}

.or-border span {
  position: ABSOLUTE;
  top: 38%;
  background: #ddd;
  height: 30px;
  width: 30px;
  left: -12px;
  border-radius: 27px;
  padding: 3px;
  color: #1e1c1c;
  border: 1px solid #ccc;
  font-size: 15px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-header .close {
  margin-top: -2px;
  text-align: right;
  width: 10%;
  padding: 0 10px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
  width: 90%;
}

.modal-header {
  padding: 6px;
  background: #f11313;
  color: #ffffff;
  display: flex;
}

.mobile-login {
  display: none;
}

@media (min-width: 250px) and (max-width: 575.98px) {
  .desktop-login {
    display: none;
  }

  .mobile-login {
    display: block;
  }
}

@media (min-width: 575.99px) and (max-width: 991.98px) {
  .desktop-login {
    display: none;
  }

  .mobile-login {
    display: block;
  }
}
</style>
